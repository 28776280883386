import styled from 'styled-components'
import {DarkBlue, GrayLight, GrayMed }  from '../Utilities/Colors'

export const Terminoscontainer = styled.div`
    font-family: 'IBM Plex Sans', sans-serif;
    margin: 5% 3%;
    background-color: ${GrayLight};
    border-radius: 5px;
    opacity: 0.8;

    &>h2{
        color:${GrayMed};
        text-align: center;
        font-weight:600;
    }
    &>h1{
        color:black;
        text-align: center;
        font-weight:700;
        margin: 0 3%;
        padding: 2% 0;
    }
    &>h3{
        color:black;
        margin:5% 0;
        text-align: right;
        font-weight:500;
        font-size: .7rem;
    }
    &>ul{
      color: ${GrayMed}
    }
    &>p{
      color: black;
      margin: 0 3%;
      font-size: 1.1vw;
    }
`
export const Logo = styled.div `
    display: flex;
    width: 100%;
    margin: 1% 0 0 80%;
`