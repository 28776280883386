import styled from 'styled-components'
import {DarkBlue, GrayMed, Red, GrayLight, Orange} from '../Utilities/Colors'

export const AvisoContainer = styled.div`
        font-family: 'IBM Plex Sans', sans-serif;
        margin: 5% 3%;
        background-color: ${GrayLight};
        border-radius: 5px;
        opacity: 0.8;
    &>p{
        margin: 0 3%;
        color: black;
        font-size: 1.1vw;
    }
    &>h2{
        color:${GrayMed};
        text-align: center;
        font-weight:600;
    }
    &>h1{
        color:black;
        text-align: center;
        font-weight:700;
        margin: 0 3%;
        padding: 2% 0;
    }
    &>h4{
        color:${Orange};
        margin:0 3%;
        /* font-weight:600; */
    }
    &>h5{
        color: ${Orange};
        margin:5% 0;
        text-align: right;
    }
    &>ul{
        color: ${GrayMed}
    }
`

export const Logo = styled.div `
    display: flex;
    width: 100%;
    margin: 1% 0 0 80%;
`