import '../../App.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/Boltum.png'


const Home = () => {
  return (
    <div className='AppGral'>   
      <h2 className='header'>
        Página en construcción
      </h2>
      <img src={logo} className="App-logo" alt="logo" />
      <div className='Links-container'>
        <Link to="/aviso-de-privacidad">
          <p>Aviso de privacidad</p>
        </Link>
        <Link to="/terminos-condiciones">
          <p>Términos y condiciones</p>
        </Link>
      </div>
        
    </div>
  );
}

export default Home;
