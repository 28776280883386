import React from 'react'
import { Terminoscontainer, Logo } from './Terms.style';
import logo from '../../assets/Boltum.png';
import { Link } from 'react-router-dom';

export default function Terminos() {
	return (
			<>
      <Logo>
				<Link to="/">
					<img src={logo} style={{height: "100px"}} alt="logo" />
				</Link>
			</Logo>
			<Terminoscontainer>
					<h1>TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA ELECTRÓNICA DE BOLTUM</h1>
					<h2>Aceptación de términos y condiciones de uso de la plataforma electrónica de BOLTUM</h2>
					<p>El presente documento establece los términos y condiciones de uso de la plataforma electrónica de BOLTUM entendiendo por esto el uso indistinto
						la página web situada en la dirección de internet <a style={{textDecoration:"none", color:"#FFA700", fontWeight:"600"}} href="/" alt="BOLTUM">boltum.mx</a> o bien, aplicación móvil, los cuales son ofrecidos por Boltum, S.A.P.I. de C.V.,
						persona moral que se encuentra constituida de conformidad con las leyes aplicables en los Estados Unidos  Mexicanos, con domicilio en Magdalena 137
						interior 1, colonia Del Valle, Alcaldía Benito Juárez, México.
						Para efectos de estos términos y condiciones a usted se le identificará como Usuario, por lo cual es muy importante que lea y acepte los mismos antes
						de continuar. En este sentido, al tener acceso al sitio web o a la aplicación móvil de BOLTUM, ya sea con su uso, descarga o instalación, se entenderá
						que ha leído y aceptado estos Términos y Condiciones. En caso de no estar de acuerdo en sujetarse a lo establecido en el presente documento, deberá suspender
						su uso y no descargar ni acceder a la información o programas que forman parte del servicio ofrecido por BOLTUM.
						Los servicios ofrecidos por BOLTUM pudieran remitir al usuario al uso de páginas web o servicios externos, para lo cual el usuario estará sujeto a los términos
						y condiciones que establezcan los terceros.
					</p>
					<h2>Objeto e información relevante de la plataforma electrónica de BOLTUM</h2>
					<p>La plataforma electrónica de BOLTUM (en lo sucesivo BOLTUM), es una herramienta de intermediación que permite al usuario realizar aportaciones a su cuenta de ahorro para el retiro a través de distintos medios
							de captación administrados por BOLTUM (los “Servicios”), sin embargo, los servicios de  administración de su cuenta de ahorro para el retiro son prestados por personas morales autorizadas por las leyes mexicanas para
							la prestación de los mismos (en lo sucesivo AFORES), por lo que debe entenderse que BOLTUM en ningún momento actuará como administrador de los recursos destinados a la cuenta de ahorro para el retiro de los usuarios,
							sino que BOLTUM sólo actúa como un facilitador entre el Usuario y los prestadores de los Servicios. Por último, los servicios ofrecidos por BOLTUM no están sujetos a la normativa aplicable a instituciones de tecnología financiera.
					</p>
					<h2>Obligaciones del usuario</h2>
						<ul>Al hacer uso de la página web o de la plataforma móvil de BOLTUM el usuario se obliga a lo siguiente:
							<li>Utilizar la página web o plataforma móvil de BOLTUM bajo su propio riesgo, apegándose siempre a lo establecido en los presentes términos y condiciones para el uso de las mismas.</li>
							<li>Actuar de buena fe y realizar operaciones legítimas.</li>
							<li>Hacerse responsable de cualquier cargo, derechos, cuotas e impuestos derivados del uso que haga de los servicios ofrecidos por BOLTUM.</li>
							<li>Proporcionar la información legítima y veraz que sea solicitada para la prestación de los servicios que ofrece BOLTUM.</li>
							<li>No permitir que ningún tercero realice operaciones a través de la página web y/o de la aplicación móvil a su nombre y sin su consentimiento.</li>
							<li>No suplantar la identidad de ninguna persona física, ni utilizar o proporcionar información falsa.</li>
							<li>No hacer uso indebido de la página web y/o de la aplicación móvil, tales como realizar operaciones falsas o ilegales; utilizar recursos, medios, programas, archivos que pudieren dañar el equipo de cómputo de otras personas o afectar
							los derechos de autor, las marcas o patentes relacionadas con el nombre comercial y software utilizado en la página web y/o en la aplicación móvil propiedad de BOLTUM; disponer, sin consentimiento de BOLTUM, de información o del software
							que sean parte o provengan de su página web y/o de la aplicación móvil, o cualquier otro acto que tenga como propósito dañar, impedir o limitar el uso de la página web o aplicación móvil.</li>
						</ul>
					<h2>Cargos por utilizar BOLTUM</h2>
					<p>Toda vez que BOLTUM no acumula el ahorro de los usuarios, sino que envía el mismo a las cuentas de ahorro correspondientes, los costos por el uso de medios de pago para realizar los cargos correspondientes son asumidos por BOLTUM, mismos que se realizan a través de PayPal (<a style={{textDecoration:"none", color:"#2A81BA"}} href="www.paypal.com/">www.paypal.com/</a>) o Conekta (<a style={{textDecoration:"none", color:"#2A81BA"}} href="conekta.com/">conekta.com/</a>).
						Para poder acceder a ciertos servicios el usuario debe ingresar y dar de alta alguna cuenta bancaria (tarjeta de débito o crédito) en Paybook (www.paybook.com/), con lo cual BOLTUM tendrá acceso únicamente al historial de gastos más no a información de credenciales ni de cuentas bancarias, tales como número de cuenta o tarjeta, expiración, tipo de cuenta o expiración de las mismas.
						BOLTUM no es responsable de un mal uso por parte de las plataformas Conekta, PayPal y/o Paybook relacionada con la acumulación de información u otros fines. Los datos que sean colocados en dichas plataformas son responsabilidad del usuario.
						La periodicidad y monto de los cargos dependen de la elección que el usuario haga en la plataforma de BOLTUM, información que puede ser modificada por el usuario en cualquier momento y según sus necesidades. Una vez que se emita la orden de cargo, la única comisión que se añadirá es de $6.00 (seis pesos 00/100 MXN) por transacción, misma que podrá sufrir modificaciones, que serán informadas al usuario con oportunidad.
						No es responsabilidad de BOLTUM transferir un monto a la cuenta de ahorro si el usuario desactiva su ahorro, o la tarjeta que utilice el usuario impida que se le genere un cobro por cualquier motivo ajeno a BOLTUM. La responsabilidad de vigencia de la tarjeta o actualización por extravío o robo de la misma es únicamente responsabilidad del usuario, ya que acepta que la continuación de cargos por parte de BOLTUM continuará hasta que el usuario notifiqué o desactive la tarjeta en cuestión.
					</p>
					<h2>Limitación de responsabilidad</h2>
						<p>BOLTUM no será responsable de daños incluidos el lucro cesante, la pérdida de datos, la lesión personal o el daño a la propiedad, ni de perjuicios relativos, o en relación con, o de otro modo derivado de cualquier uso de los Servicios, incluso aunque BOLTUM haya sido advertido de la posibilidad de dichos daños. BOLTUM no será responsable de cualquier daño, responsabilidad o pérdida que deriven de: (i) Su uso o dependencia de los Servicios o su incapacidad para acceder o utilizar los Servicios;
							o (ii) Cualquier transacción o relación entre el Usuario y el Prestador, aunque BOLTUM hubiera sido advertido de la posibilidad de dichos daños. BOLTUM no será responsable del retraso o de la falta de ejecución resultante de causas que vayan más allá del control razonable de BOLTUM.
						</p>
					<h2>Moneda aplicable a las operaciones de BOLTUM</h2>
						<p>Las operaciones que se realicen a través de la página web o aplicación móvil de BOLTUM aparecen indicadas en pesos mexicanos. </p>
					<h2>Licencia</h2>
						<p>Los Servicios están protegidos por derechos de autor, secretos comerciales, y otras leyes de propiedad intelectual. BOLTUM otorga al Usuario una licencia limitada, no exclusiva, no sublicenciable, revocable y no transferible para: (i) el acceso y uso de las Aplicaciones en su dispositivo personal solo en relación con su uso de los Servicios; y (ii) el acceso y uso de cualquier contenido, información y material relacionado que pueda ponerse a disposición a través de los Servicios, en cada caso solo para su uso personal y no comercial. BOLTUM se reserva cualquier derecho que no haya sido expresamente otorgado por el presente.
								El usuario se compromete a no exportar o reexportar los Servicios o el software subyacente o la propiedad intelectual. Transferencia o exportación de dichos servicios, el software y la propiedad intelectual está sujeto a restricciones en virtud de las leyes de control de exportación y reglamentos administrados por el Gobierno de Estados Unidos. Mediante la aceptación de estos Términos, usted también acepta cumplir con dichas leyes y reglamentos y no exportar o reexportar cualquier parte de los Servicios, ya sea directa o indirectamente, o en cualquier otra forma que sea contraria a las leyes y reglamentos.
						</p>
					<h2>Titularidad</h2>
						<p>Todos los derechos relativos a la plataforma electrónica son y permanecerán en propiedad de BOLTUM. Ninguna de estas Condiciones ni su uso de los Servicios le transfieren u otorgan ningún derecho al usuario: (i) sobre o en relación con los Servicios o la plataforma electrónica, excepto en cuanto a la licencia limitada otorgada anteriormente; o bien (ii) a utilizar o mencionar en cualquier modo a los nombres de empresa, logotipos, nombres de producto y servicio, marcas comerciales o marcas de servicio propiedad de BOLTUM.</p>
					<h2>Privacidad</h2>
						<p>La recopilación y el uso que hacemos de la información personal en relación con los Servicios se realiza de conformidad con el Aviso de Privacidad que BOLTUM pone a su disposición en el siguiente apartado electrónico el cual deberá leer y aceptar en todos sus términos <a style={{textDecoration:"none", color:"#2A81BA"}} href="/bee-hive.mx/ aviso-de-privacidad" alt="BOLTUM">bee-hive.mx/ aviso-de-privacidad</a>.</p>
					<h2>Restricciones al uso de la plataforma electrónica BOLTUM</h2>
						<p>El Usuario no podrá: (i) retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de cualquier parte de la plataforma electrónica o de los Servicios; (ii) reproducir, modificar, preparar obras derivadas sobre los Servicios o la plataforma electrónica, distribuir, licenciar, arrendar, revender, transferir, exhibir públicamente, presentar públicamente, transmitir, retransmitir o explotar de otra forma los Servicios o la plataforma electrónica, excepto como se permita expresamente por BOLTUM; (iii) descompilar, realizar ingeniería inversa o desmontar los Servicios o cualquier componente visual de la plataforma
						electrónica; (iv) enlazar, reflejar o enmarcar cualquier parte de los Servicios o la plataforma electrónica; (v) causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de la plataforma electrónica o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto de los Servicios; (vi) intentar obtener un acceso no autorizado o dañar cualquier aspecto de la plataforma electrónica o sus sistemas o redes relacionados, o (vii) cualesquier otra prohibición mencionada en los presentes términos y condiciones.
						</p>
					<h2>Servicios y contenido de Terceros.</h2>
						<p>Los Servicios podrán ponerse a disposición o ser accesible en relación con servicios y contenido de terceros (incluida la publicidad) que BOLTUM no controle. Usted reconoce que podrán ser de aplicación y de diferentes condiciones y políticas de privacidad al uso que haga de dichos servicios y contenido de terceros. BOLTUM no respalda dichos servicios y contenido de terceros y en ningún caso BOLTUM será responsable de cualquier producto o servicio de dichos terceros proveedores. </p>
					<h2>Modificaciones y actualización del contenido de BOLTUM</h2>
						<p>BOLTUM podrá realizar modificaciones, actualizaciones o mejoras de la plataforma electrónica, sin previo aviso, salvo en los casos que así lo exija la legislación aplicable. En este sentido, se entiende que el presente documento será aplicable a cualquier actualización, modificación o mejora de la plataforma, motivo por el cual se invita al usuario a revisar periódicamente el presente apartado para conocer la última versión del mismo.
								En este sentido, si el usuario no está de acuerdo con las modificaciones realizadas podrá suspender el uso del servicio. Si el usuario accede y usa la plataforma electrónica de BOLTUM después de la fecha en que se hubieren realizados los cambios y modificaciones se entenderá que se encuentra conforme con los mismos.
						</p>
					<h2>Actualización de la información del usuario de BOLTUM</h2>
						<p>Los cambios realizados por el usuario en sus datos de contacto deberán ser actualizados de inmediato en el perfil creado en nuestra plataforma, esto con la finalidad de poder brindarle información relevante y enviarle las notificaciones relacionadas con los servicios ofrecidos por BOLTUM.</p>
					<h2>Comunicación con el usuario</h2>
						<p>Al crear una cuenta con BOLTUM usted acepta que se le podrá contactar mediante un correo electrónico enviado a su dirección electrónica, según lo dispuesto en su Cuenta. Adicionalmente, usted acepta que se le puedan enviar mensajes de texto informativos vía whatsapp o tradicional como parte de la actividad comercial normal de su uso de los Servicios. Usted podrá solicitar la no recepción de mensajes de texto informativos de BOLTUM en cualquier momento, indicando que no desea recibir más dichos mensajes, junto con el número de
							teléfono del dispositivo móvil que recibe los mensajes. Usted reconoce que solicitar la no recepción de mensajes de texto informativos vía whatsapp o tradicional podrá afectar al uso que usted haga de los Servicios.
						</p>
					<h2>Renuncia</h2>
						<p>Los servicios se proporcionan “tal cual” y “como disponibles”. BOLTUM renuncia a toda declaración y garantía, expresa, implícita o estatutaria, no expresamente establecida en estas condiciones, incluidas las garantías implícitas de comerciabilidad, idoneidad para un fin particular y no violación. Además, BOLTUM no hace declaración ni presta garantía alguna relativa a la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los Servicios solicitados o que los Servicios no serán interrumpidos o estarán libres de errores. BOLTUM no garantiza la calidad, idoneidad, seguridad o habilidad de los Prestadores. El Usuario acepta
						que todo riesgo derivado de los servicios como suyo.
						</p>
					<h2>Soporte</h2>
						<p>BOLTUM brinda soporte los 365 días del año, en caso de que lo necesite. Para recibir soporte, puede ingresar a la sección de “Contacto”, ubicada en el menú principal de la aplicación o a través de la página web.
							Las limitaciones y la renuncia en este apartado no pretenden limitar la responsabilidad o alterar sus derechos como consumidor que no puedan excluirse según la ley aplicable.
						</p>
					<h2>Disposiciones generales</h2>
						<p>El Usuario no podrá ceder ni transferir los derechos y obligaciones establecidas en estos Términos y Condiciones, en todo o en parte, sin el consentimiento previo por escrito de BOLTUM.
								El Usuario da su aprobación a BOLTUM para ceder o transferir estos Términos y Condiciones, en todo o en parte, incluido a: (i) una subsidiaria o un afiliado; (ii) un adquirente del capital, del negocio o de los activos de BOLTUM; o (iii) un sucesor por fusión.
								Si cualquier disposición de estos Términos y Condiciones se considerara ilegal, nula o inexigible, ya sea en su totalidad o en parte, de conformidad con cualquier legislación, dicha disposición o parte de esta se considerará que no forma parte de estos Términos y Condiciones, aunque la legalidad, validez y exigibilidad del resto de las disposiciones de estos Términos y Condiciones no se verá afectada. En ese caso, las partes deberán reemplazar dicha disposición ilegal, nula o inexigible, en todo o en parte por una disposición legal, válida y exigible que tenga, en la medida de lo posible, un efecto similar al que tenía la disposición ilegal, nula o inexigible, dados los contenidos y el propósito de estos Términos y Condiciones. Estas Condiciones constituyen el contrato íntegro y el entendimiento entre las partes en relación con el objeto y sustituye y reemplaza a todos los contratos o acuerdos anteriores o contemporáneos en relación con dicho objeto. En estas Condiciones, las palabras “incluido/a/os/as” e “incluye/n” significan “incluido, de forma meramente enunciativa”.
						</p>
					<h2>Contacto </h2>
					<p>Para el envío de dudas o comentarios relacionados con nuestro servicio el usuario podrá contactarnos enviando un correo electrónico a la siguiente dirección contacto@skunksproject.com o bien, llamando al (52) 5528442452. </p>
					<h3>El uso de esta página o de su aplicación móvil implica que el Usuario ha leído y acepta estos términos y condiciones.</h3>
			</Terminoscontainer>
			</>
	)
}
