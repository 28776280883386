import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Components/Home/Home';
import Privacy from './Components/Legal/Privacy';
import Terminos from './Components/Legal/Terms';
import AccountDeletion from './Components/AccountDeletion';

const Router = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/aviso-de-privacidad" component={Privacy} />
      <Route
        exact
        path="/terminos-condiciones"
        component={Terminos}
      />
      <Route
        exact
        path="/eliminar-cuenta"
        component={AccountDeletion}
      />
    </Switch>
  );
};
export default Router;
