import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './AccountDeletion.css';

const AccountDeletion = () => {
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        'https://w4gsp9576l.execute-api.us-east-1.amazonaws.com/dev/sendemail',
        {
          email: email,
        }
      );

      console.log(response.data);
      setShowModal(true); // Show the modal on successful request
    } catch (error) {
      console.error('Error sending email:', error);
      // Handle error here, such as showing an error message
      setShowModal(true); // Show the modal on successful request
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    history.push('/');
  };

  return (
    <div className="account-deletion-container">
      <div className="content-wrapper">
        <h2>Solicitud de Eliminación de Cuenta</h2>
        <p>
          Si deseas eliminar tu cuenta de Boltum y los datos
          asociados, sigue los siguientes pasos. Tu solicitud se
          procesará de acuerdo con nuestras políticas de retención de
          datos.
        </p>
        <ol>
          <li>
            Introduce tu correo electrónico en el campo de entrada.
          </li>
          <li>
            En un máximo de 24 horas, te contactaremos por correo
            electrónico para confirmar que deseas eliminar tu cuenta
            con nosotros.
          </li>
          <li>
            Después de eso, podemos tardar entre 24 y 48 horas en
            eliminar completamente tus datos. Conservamos tu correo
            electrónico, fecha de creación de la cuenta, fecha de
            solicitud de eliminación de la cuenta y fecha de
            eliminación efectiva de la cuenta durante 12 meses para
            fines de auditoría. Recibirás un correo una vez que todo
            este proceso haya terminado.
          </li>
        </ol>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Tu correo electrónico"
            value={email}
            onChange={handleEmailChange}
            className="email-input"
          />
          <button type="submit" className="submit-button">
            Iniciar Proceso de Eliminación
          </button>
        </form>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>
              Hemos recibido tu solicitud. En breve estaremos en
              contacto contigo.
            </p>
            <button onClick={handleModalClose}>Entendido</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountDeletion;
