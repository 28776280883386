//export const DarkBlue = "#FAFAFA";
export const GrayLight = "#EDEDED"
export const GrayMed = "#7A7A7A"


///Nuevos
export const DarkBlue = "#FFFFFF";
export const Green= "#93EC40";

export const Orange = "#EFA430";
export const Red= "#C70039";